import type { InjectionKey } from 'vue'

export type EnterHousingWorld = {
  worldId: string
  channelId?: string
}
export type EnterWorldPortal = {
  ingameUrl: string
}
export type EnterInGameOption = EnterHousingWorld | EnterWorldPortal

export const useInGame = () => {
  const housingWorld = useHousingWorlds()
  const myProfile = useMyProfile()
  const housing = useHousing()
  const channelSession = useChannelSession()
  const auth = useAuth()

  const enterInGame = (options: EnterInGameOption) => {
    if ('worldId' in options) {
      // ハウジングワールド
      location.href = housingWorld.getHousingWorldURL(
        options.worldId,
        options.channelId
      )
      return
    }

    // ワールドポータル
    const visitComplete = window.open(options.ingameUrl, '_blank')
    if (!visitComplete) {
      location.href = options.ingameUrl
    }
  }

  /**
   * ログインユーザー情報とルーム名をもとにトークルームを作成する
   * その後、インゲームへ遷移する
   * @param roomName トークルーム名
   */
  const createTalkroomAndEnterInGame = async (roomName: string) => {
    if (!myProfile.state.value.profile) {
      throw new Error('empty profile')
    }

    const myroomList = await housing.getMyRooms()
    if (!myroomList.length) {
      throw new Error('empty myroom')
    }

    const jwt = auth.state.value.idToken || ''
    const worldId = `MyRoom-${myProfile.state.value.profile.vketId}`
    await channelSession.createTolkroom(jwt, worldId, roomName)

    enterInGame({
      worldId,
      channelId: channelSession.positionChannel.value?.channelId,
    })
  }

  return {
    enterInGame,
    createTalkroomAndEnterInGame,
  }
}

export type InGameComposable = ReturnType<typeof useInGame>

export const inGameInjectionKey: InjectionKey<InGameComposable> =
  Symbol('inGame')
