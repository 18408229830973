import type { InjectionKey } from 'vue'
import { isValueOf } from '@/utils/zod'
// models
import { PositionChannel, ChannelSessionUser } from '@/models/channelSession'

// repository
import { isFetchError } from '@/composables/repository/useOhmyfetch'
import { useRepositoryFactory } from '@/composables/repository/useRepositoryFactory'
import {
  PostCreatePositionChannelRequest,
  getSpatiumSearchResponse,
  getUserSearchResponse,
  postCreateChannelSessionResponse,
  postCreateGuestUserResponse,
  postCreatePositionChannelRequest,
  postCreatePositionChannelResponse,
  putEnterNewPositionChannelResponse,
} from '@/composables/repository/useChannelSessionRepository'

export const useChannelSession = () => {
  const repositoryFactory = useRepositoryFactory()
  const channelSessionRepository = repositoryFactory.get(
    'channelSessionRepository'
  )

  const positionChannelList = ref<PositionChannel[]>([])
  const authenticatedJwtToken = ref<string | null>(null)
  const channelJwtToken = ref<string | null>(null)
  const positionChannel = ref<PositionChannel | null>(null)

  /**
   * トークルーム一覧用
   * vketIdに紐づく「spatium_code=VketMyRoom」の一覧を取得
   * @param vketIdList
   * @param limit
   * @returns
   */
  const userSearchForTalkRoom = async (vketIdList: string[], limit: number) => {
    // vketIdListが空ならトークルームも空
    if (vketIdList.length === 0) {
      positionChannelList.value = []
      return []
    }

    try {
      const response = await channelSessionRepository.get.userSearch({
        spatiumCode: 'VketMyRoom',
        userCodes: vketIdList,
        limit,
      })

      if (!response) {
        throw new Error('response is empty.')
      }

      if (!isValueOf(getUserSearchResponse, response)) {
        console.error('An API response is different.')
      }

      positionChannelList.value = response.data.positionChannels
      return response.data.positionChannels
    } catch (e) {
      if (isFetchError(e)) {
        console.error(e)
      }
      throw e
    }
  }

  /**
   * トークルーム一覧用
   * 「spatium_code=VketMyRoom」のトークルーム一覧を取得
   * @returns
   */
  const searchForTalkRoom = async (
    limit: number,
    filterEmpty = true // 空のトークルームを除外するか
  ) => {
    try {
      const response = await channelSessionRepository.get.spatiumSearch(
        'VketMyRoom',
        { limit, filterEmpty }
      )

      if (!response) {
        throw new Error('response is empty.')
      }

      if (!isValueOf(getSpatiumSearchResponse, response)) {
        console.error('An API response is different.')
      }

      positionChannelList.value = response.data.positionChannels
      return response.data.positionChannels
    } catch (e) {
      if (isFetchError(e)) {
        console.error(e)
      }
      throw e
    }
  }

  /**
   * トークルームトップの一覧表示用
   * トークルームを10件取得する
   * vketIdListの一覧をキーに優先で取得し10件未満の場合は新基準でトークルームを取得
   * @param vketIdList
   * @returns
   */
  const searchPositionChannelForTalkRoom = async (vketIdList: string[]) => {
    const userTalkRoomList = await userSearchForTalkRoom(vketIdList, 10)
    if (userTalkRoomList.length >= 10) {
      return userTalkRoomList
    }
    const talkRoomList = await searchForTalkRoom(10)
    // NOTE: フォローユーザーのルームと最新のルームが重複することがあるので重複を排除しつつ10件取得する
    const mergeList = userTalkRoomList
      .concat(
        talkRoomList.filter(
          (room) =>
            !userTalkRoomList.find(
              (userRoom) => room.channelId === userRoom.channelId
            )
        )
      )
      .slice(0, 10)
    positionChannelList.value = mergeList
    return mergeList
  }

  /**
   * ログイントークン(jwt)を用いて認証を行う
   * @param jwt
   */
  const createGuestUser = async (jwt = '') => {
    try {
      const response = await channelSessionRepository.post.createGuestUser({
        authJwt: jwt,
      })

      if (!response) {
        throw new Error('response is empty.')
      }

      if (!isValueOf(postCreateGuestUserResponse, response)) {
        console.error('An API response is different.')
      }

      // トークンの更新
      authenticatedJwtToken.value = response.userJwt ?? null
    } catch (e) {
      if (isFetchError(e)) {
        console.error(e)
      }
      throw e
    }
  }

  /**
   * NOTE: 先にcreateGuestUser実行する必要がある
   * @prams userJwt createGuestUserのレスポンスヘッダ[X-User-Jwt-Session]
   */
  const createChannelSession = async () => {
    try {
      if (!authenticatedJwtToken.value)
        throw new Error('Token not authenticated.')
      const response = await channelSessionRepository.post.createChannelSession(
        authenticatedJwtToken.value
      )

      if (!response) {
        throw new Error('response is empty.')
      }

      if (!isValueOf(postCreateChannelSessionResponse, response)) {
        console.error('An API response is different.')
      }

      // トークンの更新
      authenticatedJwtToken.value = response.userJwt ?? null
      channelJwtToken.value = response.jwt ?? null
    } catch (e) {
      if (isFetchError(e)) {
        console.error(e)
      }
      throw e
    }
  }

  const createPositionChannel = async (
    spatiumCode: string,
    worldId: string,
    params: PostCreatePositionChannelRequest
  ) => {
    try {
      if (!channelJwtToken.value || !authenticatedJwtToken.value)
        throw new Error('Token not authenticated.')
      const response =
        await channelSessionRepository.post.createPositionChannel(
          authenticatedJwtToken.value,
          channelJwtToken.value,
          spatiumCode,
          worldId,
          params
        )

      if (!response) {
        throw new Error('response is empty.')
      }

      if (!isValueOf(postCreatePositionChannelResponse, response)) {
        console.error('An API response is different.')
      }

      positionChannel.value = response.data.positionChannel
      // トークンの更新
      authenticatedJwtToken.value = response.userJwt ?? null

      return response.data
    } catch (e) {
      if (isFetchError(e)) {
        console.error(e)
      }
      throw e
    }
  }

  const enterNewPositionChannel = async () => {
    try {
      if (!authenticatedJwtToken.value || !channelJwtToken.value)
        throw new Error('Token not authenticated.')
      if (!positionChannel.value)
        throw new Error('The position channel has not been acquired.')
      const response =
        await channelSessionRepository.put.enterNewPositionChannel(
          authenticatedJwtToken.value,
          channelJwtToken.value,
          {
            positionChannelId: positionChannel.value.channelId,
            authenticateJwt: authenticatedJwtToken.value,
          }
        )

      if (!response) {
        throw new Error('response is empty.')
      }

      if (!isValueOf(putEnterNewPositionChannelResponse, response)) {
        console.error('An API response is different.')
      }

      positionChannel.value = response.data.positionChannel
      return response.data
    } catch (e) {
      if (isFetchError(e)) {
        console.error(e)
      }
      throw e
    }
  }

  /**
   * トークルームの作成を行う<br>
   * ルーム作成時は以下を固定値で実行する
   * ・channel_type = public
   * ・spatium_code = VketMyRoom
   * @param jwt
   * @param worldId
   * @param roomName
   */
  const createTolkroom = async (
    jwt: string,
    worldId: string,
    roomName: string
  ) => {
    // Note: 以下の流れでトークルームを作成する
    // 1. 連携用JWTでチャンネルセッションAPI（ChS API）に認証する
    // 2. ChS APIでセッションを取得する
    // 3. ChS APIで座標チャンネルを作成する
    await createGuestUser(jwt)
    await createChannelSession()
    const params = postCreatePositionChannelRequest.parse({
      name: roomName,
      channelType: 'public',
    })
    await createPositionChannel('VketMyRoom', worldId, params)
  }

  return {
    positionChannel: readonly(positionChannel),
    positionChannelList: readonly(positionChannelList),
    userSearchForTalkRoom,
    searchForTalkRoom,
    searchPositionChannelForTalkRoom,
    createGuestUser,
    createChannelSession,
    createTolkroom,
    createPositionChannel,
    enterNewPositionChannel,
  }
}

export type ChannelSessionComposable = ReturnType<typeof useChannelSession>

export const ChannelSessionInjectionKey: InjectionKey<ChannelSessionComposable> =
  Symbol('channel_session')
